<template>
  <div>

    <app-step step="0" />

    <div class="container pt-4">
      <div class="row px-3">
        <div class="col-sm-3 border border-bottom-0">
          <div class="row text-primary d-flex justify-content-center">
            <div class="col-2 my-auto py-2"><h4 class="my-0"><i class="fad fa-user"></i></h4></div>
            <div class="col-8 my-auto py-2"><h5 class="my-0">Invitado</h5></div>
          </div>
        </div>
      </div>
    </div>

    <div class="container pb-5">

      <hr class="mt-0">

      <div class="row px-3">
        <div class="col-12">
          <p class="text-muted small mb-0">
            Actualmente tu perfíl es de Invitado, para continuar es necesario que proporciones los datos siguientes.
          </p>
          <p class="text-muted small mb-0">
            Al hacer clic en "Continuar como Invitado" aceptas nuestras
            <a href="https://x-orsystem.com/privacidad" target="_blank">Políticas de Privacidad</a>
          </p>
          <p class="text-muted small">
            Para iniciar sesión has click
            <a href="https://x-orsystem.com/index.php?route=account/login">aquí</a>
          </p>
        </div>
      </div>
      
      <div class="row d-flex justify-content-between">
        
        <b-form class="col-sm-3">
          <b-form-input
          v-model="form.customer_firstname"
            class="mb-2 mr-sm-2 mb-sm-0"
            placeholder="Nombre (s)"
          ></b-form-input>
        </b-form>
        
        <b-form class="col-sm-3">
          <b-form-input
          v-model="form.customer_lastname"
            class="mb-2 mr-sm-2 mb-sm-0"
            placeholder="Apellido (s)"
          ></b-form-input>
        </b-form>

        <b-form class="col-sm-3">
          <b-form-input
          v-model="form.customer_phone"
            class="mb-2 mr-sm-2 mb-sm-0"
            placeholder="Número celular"
          ></b-form-input>
        </b-form>

        <b-form class="col-sm-3">
          <b-form-input
          v-model="form.customer_email"
            class="mb-2 mr-sm-2 mb-sm-0"
            placeholder="Email"
          ></b-form-input>
        </b-form>

      </div>
      
      <div class="row pt-4">
        <div class="col-3 ml-auto">
          <b-button v-on:click="continue_as_guest()" variant="primary" block>Continuar como Invitado</b-button>
        </div>
      </div>

    </div>

  </div>
</template>

<script>
import axios from 'axios'
import settings from '../settings'

export default {
  name: 'Visitor',
  data() {
    return {
      form: '',
      xwt: ''
    }
  },
  components: {
    AppStep: () => import('@/components/AppStep.vue')
  },
  methods: {
    continue_as_guest() {
      var headers = { xwt: this.xwt }

      var payload = {
        customer_id: 40,
        customer_group_id: 6,
        firstname: this.form.customer_firstname,
        lastname: this.form.customer_lastname,
        email: this.form.customer_email,
        telephone: this.form.customer_phone,
        ip: ''
      }

      var coded_payload = btoa(JSON.stringify(payload))

      axios.get(settings.api().endpoint + settings.api().path + '/get_token/' + coded_payload, { headers }).then( (r) => {
        this.xwt = r.data.data

        this.$store.commit('set_token', this.xwt)
        this.$store.commit('set_customer', JSON.parse(atob(this.xwt.split('.')[1])) )

        this.$router.push('/order')
      })
    }
  },
  created() {
    document.title = "X-orsystem Pay"
    this.xwt = ''
    
    this.form = {
      customer_firstname: '',
      customer_lastname: '',
      customer_phone: '',
      customer_email: ''
    }
  },
}
</script>